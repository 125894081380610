    @keyframes swap-image {
        0% {
            background: url("../../assets/img/banner.webp");
        }

        33% {
            background: url("../../assets/img/banner-3.webp");
        }

        66% {
            background: url("../../assets/img/banner-2.webp");
        }
        100% {
            background: url("../../assets/img/banner.webp");
        }
    }

.hero {
    height: 70vh;
    -webkit-animation: swap-image 15s infinite normal;
    animation: swap-image 15s infinite normal;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
    h1{
        color: white;
        font-size: 48px;
        line-height: 58px;
        text-shadow: 4px 4px 2px rgba(0,0,0,0.6)
    }
}

@media (max-width: 575.98px) { 
        @keyframes swap-image {
        0% {
            background: url("../../assets/img/banner-xs.webp");
        }

        33% {
            background: url("../../assets/img/banner-3-xs.webp");
        }

        66% {
            background: url("../../assets/img/banner-2-xs.webp");
        }
        100% {
            background: url("../../assets/img/banner-xs.webp");
        }
    }
    .hero{
        align-content: start;
        padding-top: 2em;
        h1{
        font-size: 35px;
        line-height: 42px; 
        }
    }
 }