.mainNavBar {
  span {
    svg {
      height: 35px;
    }
  }
}

.customNavLink {
  display: flex;
  align-items: center;
  a {
    color: black;
    text-decoration: none;
    font-weight: 700;
    margin-right: 2em;
    :hover {
      color: darkgray;
    }
  }
}

.navActive {
  color: darkgray !important;
}

.navLink {
  display: flex;
  justify-content: flex-end;
}

.customBrand {
  img {
    height: 35px;
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .customOffCanvas {
    flex-direction: column-reverse;
  }
}
