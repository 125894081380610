//
.cardStyle {
  position: relative;
  transform: scale(1);
  transition: all 0.5s ease;
  z-index: 1;

  :first-child {
    :hover {
      rotate: initial;
      box-shadow: initial;
      -webkit-box-shadow: initial;
      -moz-box-shadow: initial;
      transform: initial;
    }
  }
  :hover {
    // box-shadow: -2px -1px 43px 6px rgba(0,0,0,0.37);
    // -webkit-box-shadow: -2px -1px 43px 6px rgba(0,0,0,0.37);
    // -moz-box-shadow: -2px -1px 43px 6px rgba(0,0,0,0.37);
    // z-index: 5 !important;
    // transform: scale(1.02);
    transition: all 0.5s ease;
    transform: perspective(30em) rotateX(5deg);
    // rotateY(-5deg);
    z-index: 5;
    box-shadow: rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
      rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
  }
}
//
.exporterLogo {
  position: relative;
  margin: 0px;
  padding: 20px;
  text-align: center;
  img {
    position: relative;
    width: 85%;
  }
}

//
.cardContainer {
  // background-color: white;
  padding: 1em;
}

//
.footer {
  // margin-top: 170px;
  a {
    text-decoration: none;
    font-weight: 700;
  }
  svg {
    margin-right: 1em;
  }
}
//
.brandTitle {
  font-size: 16px;
  li {
    text-indent: -24px;
    margin-left: 20px;
    position: relative;
    line-height: 45px;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: rgb(131, 131, 131);
      top: 90%;
      left: 0;
    }
  }
}
