.langFlag{
    width: 30px;
    padding: 0px;
    margin-left: 10px;
    
    img{
        height: 30px;
        width: auto;
    }
}

.langSwitch{
    justify-content: end;
}

