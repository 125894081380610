.videoLouncher{
    padding: 0 1em 0 1em;
    display: flex;
    // justify-content: space-between;
    div{
        text-align: center;
        margin: 1em;
        // background-color: aqua;

    }
    svg{
        // color: blue !important;
    }
}

