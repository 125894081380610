@import "../../scss/color.scss";

.sectionHeader {
  background-color: $blueDark-hex;
  min-height: 14em;
  color: $whiteCool-hex;
  text-align: center;
  position: relative;
}

.borderContainer {
  display: flex;
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  top: 0px;
  img {
    transform: scaleY(-1);
    position: absolute;
    top: -12px;
    width: 100%;
    min-height: 30% !important;
    object-fit: cover;
  }
}

.textContainer {
  height: 100% !important;
  padding-top: 3em;
}
