.footerPage{
    min-height: 250px;
    background-color: #dcdcdc;
    color: #fff;
    display: flex;
    
}
.logo{
    display: flex;
    justify-content: center;
    align-content: center;
}

.poweredBy{
    margin-top: 2em;
    text-align: center;
   a{
        font-size: 15px;
        color: #161616;
        font-weight: 700;
        text-decoration: none;
    }
    a:hover {
       color: darkgrey;

   }
}