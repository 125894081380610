.carouselFullWith {
  background-color: rgb(210, 207, 207);
  padding: 2em;
  video {
    width: 100%;
  }
}

.setInner {
  height: 312px;
}

.text {
  display: flex;
  align-content: center;

  h2 {
    margin-top: 2em;
    display: flex;
    font-size: large;
  }
  button {
    text-align: center;
  }
}

.customSlide {
  //   border: 1px solid red;

  .overContainer {
    // border: 1px solid green;

    position: relative;
    .overText {
      position: absolute;
      //   border: 1px solid yellow;
      margin: 0px;
      padding: 0px;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      color: rgb(255, 255, 255);
      z-index: 10;
      padding-left: 11%;
      padding-right: 11%;

      small {
        // border: 1px solid greenyellow;
        font-size: 18px;
      }
    }
    .colorOver {
      background-color: rgb(0, 0, 0);
      opacity: 0.3;
      min-width: 80%;
      min-height: 75%;
      position: absolute;
      z-index: 9;
      top: 0px;
      left: 0px;
      transform: translate(12%, 10%);
    }
  }
}

@media (max-width: 576px) {
  .carouselFullWith {
    video {
      //   margin-top: 2em;
      height: auto;
      width: 100%;
    }
    .colorOver {
      margin-top: 2em;
    }
  }

  .setInner {
    height: unset;
  }
  .text {
    button {
    }
  }
  .overText {
    h4 {
      font-size: 18px;

      small {
        font-size: 12px !important;
        line-height: 12px !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .setInner {
    height: unset;
    video {
      margin-top: 2em;
      height: auto;
      width: 100%;
    }
  }
}
