/* Color Theme Swatches in Hex */
$redDark-hex: #a90411;
$redStrong-hex: #d61622;
$whiteCool-hex: #f1f2f5;
$blueStrong-hex: #01095a;
$blueDark-hex: #020342;

/* Color Theme Swatches in RGBA */
$Muay-Thai-Fest-2024-1-rgba: rgba(169, 4, 17, 1);
$Muay-Thai-Fest-2024-2-rgba: rgba(214, 22, 34, 1);
$Muay-Thai-Fest-2024-3-rgba: rgba(241, 242, 245, 1);
$Muay-Thai-Fest-2024-4-rgba: rgba(1, 9, 90, 1);
$Muay-Thai-Fest-2024-5-rgba: rgba(2, 3, 66, 1);

/* Color Theme Swatches in HSLA */
$Muay-Thai-Fest-2024-1-hsla: hsla(355, 95, 33, 1);
$Muay-Thai-Fest-2024-2-hsla: hsla(356, 81, 46, 1);
$Muay-Thai-Fest-2024-3-hsla: hsla(225, 16, 95, 1);
$Muay-Thai-Fest-2024-4-hsla: hsla(234, 97, 17, 1);
$Muay-Thai-Fest-2024-5-hsla: hsla(239, 94, 13, 1);
