.cardGrid{
    padding-bottom: 20px;

    li{
        list-style: none;
    }
    h2{
        font-size: 24px;
    }
    h3{
        font-size: 18px;
    }
    h4{
        font-size: 18px;
    }

    img{
        height: auto;
        width: 100%;

    }
   

}
.accordionBody{
    
    svg{
        margin-right: 0.5em;
    }
}
.socialIcons{

    div{
        text-align: center;
        padding: 10px;
        a{
            // color: red;
        }   
    }
}

.accordionHeader{
    button{
        font-size: 12px;
        text-transform: uppercase;
        // background-color: aquamarine;

    }
}