.wrapper {
    position: relative;
    background-image:
    linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255 , 255, 255, 0.9)),
    url('../../assets/img/banner-3.jpg');
    background-size:cover;
    background-position: bottom;
    background-attachment: fixed;   
}

.header{
    color: rgb(40, 40, 40) ;
    :first-child{
        text-align: left;
        align-self: flex-start;
    }
}
.formStyle{
    background-color: rgba(255, 255, 255, 0.52);
    border-radius: 8px;
    padding: 20px 0 20px 0
}

.formWarper{
    display: block;
    position: relative;
}

.eventDate{
    align-self: center;
}

@media (max-width: 1199.98px) {
    .header{
        h1{
            font-size: 20px;
        }    
    }
    .eventDate{
        h1{
            font-size: 20px;
        }
    }
 }